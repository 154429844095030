@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9rem;
  width: 100%;
  min-width: 100%;
  background: transparent;
  overflow-x: hidden !important;
  /* overflow-y: hidden !important; */
}

input[type="text"] {
  font-size: 0.8rem;
}
input[type="password"] {
  font-size: 0.8rem;
}
input[type="email"] {
  font-size: 0.8rem;
}
input[type="date"] {
  font-size: 0.8rem;
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-wrapper .sidebar-wrapper-right,
.sidebar-wrapper-right .sidebar-brand > a,
.sidebar-wrapper-right .sidebar-dropdown > a:after,
.sidebar-wrapper-right
  .sidebar-menu
  .sidebar-dropdown
  .sidebar-submenu
  li
  a:before,
.sidebar-wrapper-right ul li a i,
.page-wrapper-right .page-content,
.sidebar-wrapper-right .sidebar-search input.search-menu,
.sidebar-wrapper-right .sidebar-search .input-group-text,
.sidebar-wrapper-right .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #1e2229;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  height: 100vh;
}

.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}

.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
  /* right: 0px; */
}
.page-wrapper.comments-toggled .sidebar-wrapper-right {
  right: 0px;
}

@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 250px;
    /* padding-right: 250px; */
    /* padding-right: 250px; */
  }
  .page-wrapper.comments-toggled .page-content {
    padding-right: -250px;
  }
}

/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  width: 250px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  /* right: -300px; */
  z-index: 999;
}

.sidebar-wrapper-right {
  width: 350px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  right: -350px;
  z-index: 1000;
  color: white;
  background-color: #434344;
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper-right ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

.sidebar-wrapper-right a {
  text-decoration: none;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
  width: 100%;
  /* stylelint-disable-next-line no-duplicate-selectors */
  padding-left: 0px;
  height: 100%;
}

.page-wrapper .page-content > div {
  padding: 0 30px;
}

.page-wrapper .page-content {
  overflow-x: hidden;
}
